.speakers-list {
    
    .speakers-container {
        display: flex;
        flex-wrap: wrap;
    }

    .speaker {
        display: flex;
        flex-direction: column;
        margin: rem(50) 0;

        h3 {
            margin-top: rem(10);
        }

        img {
            display: inline-block;
            margin: 0 auto;
        }

        &-position {
            font-size: rem(18);
            color: $purple;
            line-height: rem(24);
            margin-bottom: 0;
        }

        hr {
            width: rem(40);
            border-top: rem(2) solid $purple;
        }

        a {
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
}