.exhibitors {
    column-count: 3;
    padding-left: 0;
    list-style: none;

    @media (max-width: $screen-xs) {
        column-count: 1;
    }


    li {
        margin-bottom: rem(5);

        .stand-number {
            color: $pink;
            padding-right: rem(10);
            display: inline-block;
            width: 20%;
            text-align: right;
        }

        .exhibitor-name {
            display: inline-block;
            width: 70%;
            vertical-align: top;
        }
    }
}
