.form,
.webform-client-form {
    label {
        font-weight: bold;
        font-size: rem(16);
        color: $blue;
        line-height: rem(32);
        margin-bottom: rem(15);
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    textarea,
    select {
        border: rem(2) solid $blue;
        padding: rem(10) rem(15);
        font-size: rem(16);
        color: #2b2b2b;
        line-height: rem(24);
        border-radius: 0;
        height: auto;
    }

    select {
        height: rem(46);
        -webkit-border-radius: 0;
        border: 0;
        outline: rem(2) solid $blue;
        outline-offset: -1px;
    }

    input[type="checkbox"] {
        display: none;

        + label {
            padding-left: rem(40);
            background-image: url("../images/icons/primary/nhs/checkbox-empty.png");
            background-repeat: no-repeat;
            min-height: 32px;
            margin: 0;

            &:hover {
                cursor: pointer;
                background-image: url("../images/icons/primary/nhs/checkbox-empty-hover.png");
            }
        }

        &:checked + label {
            background-image: url("../images/icons/primary/nhs/checkbox-filled.png");

            &:hover {
                background-image: url("../images/icons/primary/nhs/checkbox-filled-hover.png");
            }
        }
    }
    input[type="radio"] {
        display: none;

        + label {
            padding-left: rem(40);
            background-image: url("../images/icons/primary/nhs/radio-empty.png");
            background-repeat: no-repeat;
            min-height: 32px;
            margin: 0;

            &:hover {
                cursor: pointer;
                background-image: url("../images/icons/primary/nhs/radio-empty-hover.png");
            }
        }

        &:checked + label {
            background-image: url("../images/icons/primary/nhs/radio-fill.png");

            &:hover {
                background-image: url("../images/icons/primary/nhs/radio-fill-hover.png");
            }
        }
    }

    #boardno-fieldset-wrapper {
        .panel-default > .panel-heading {
            background: none;
            border: 0;
            padding: 0;
        }

        .panel-title {
            font-weight: bold;
            font-size: 1rem;
            color: $blue;
            line-height: 2rem;
        }

        label {
            display: none;
        }

        .form-group {
            display: inline-block;
            margin-bottom: 0;
        }

        .form-item-poster-number-category {
            width: 30%;
            margin-right: rem(20);
        }
        
        .form-item-poster-number-number {
            width: 60%;
        }
    }

    // Bootstrap overrides
    .form-group {
        margin-bottom: rem(35);
    }

    .panel {
        margin: 0;
        padding: 0;
        border: 0;
        box-shadow: none;
    }

    .panel-body {
        margin: 0;
        padding: 0;
        border: 0;
    }

    .description {
        font-size: rem(14);
        line-height: rem(24);
    }

    .help-block {
        font-size: rem(14);
        color: $charcoal;
        line-height: rem(22);
    }

    .input-group-btn {
        display: inline-block;
    }

    &.register-interest {
        margin-top: rem(60);

        label {
            margin-bottom: rem(10);
        }

        input[type="text"],
        input[type="email"] {
            display: block;
            width: 100%;
            margin-bottom: rem(10);
        }
    }

    &.poster-upload-form {
        input[type="text"],
        input[type="email"] {
            display: block;
            width: 100%;
            margin-bottom: 0;
            padding: rem(10) rem(15);
        }

        .form-field {
            margin-bottom: rem(30);
        }

        hr {
            border-top: 2px solid $blue;
        }
    }
}

.poster-abstract {
    padding-top: rem(60);

    .lead-author-intro,
    .additional-author-intro {
        margin-bottom: rem(20);
    }
}

.views-exposed-form {
    padding: rem(30) 0;

    .views-exposed-widget {
        float: none;
    }

    .views-widget-label {
        font-weight: bold;
        font-size: rem(20);
        color: $blue;
        line-height: rem(24);
    }

    .form-item {
        display: inline-block;
        margin: rem(10) rem(80) rem(10) 0;

        @media (max-width: $screen-xs) {
            margin-right: 0;
        }
    }

    .form-control {
        background: none;
        border: 0;
        height: auto;
        border-radius: 0;
        box-shadow: none;
    }

    .form-actions {
        text-align: right;

        @media (max-width: $screen-xs) {
            text-align: center;
        }
    }

    .views-reset-button,
    .views-submit-button {
        display: inline-block;
    }

    .views-reset-button {
        margin-right: rem(30);

        button {
            position: relative;
            margin: 0 !important;
            padding: 0;
            background: none;
            border: 0;
            font-weight: bold;
            font-size: rem(16);
            color: $blue;
            line-height: rem(36);
            padding-right: rem(25);

            &:before {
                position: absolute;
                content: "";
                right: 0;
                transform: translateY(70%);
                background-image: url("../images/icons/primary/nhs/close.png");
                background-repeat: no-repeat;
                background-position: right center;
                width: 15px;
                height: 15px;
                background-size: cover;
                display: inline-block;
            }
        }
    }

    .bef-select-as-checkboxes {
        padding: 0;

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + label {
                padding-left: rem(40);
                background-image: url("../images/icons/primary/nhs/checkbox-empty.png");
                background-repeat: no-repeat;
                min-height: 32px;
                margin: 0;
                line-height: rem(32);

                &:hover {
                    cursor: pointer;
                    background-image: url("../images/icons/primary/nhs/checkbox-empty-hover.png");
                }
            }

            &:checked + label {
                background-image: url("../images/icons/primary/nhs/checkbox-filled.png");

                &:hover {
                    background-image: url("../images/icons/primary/nhs/checkbox-filled-hover.png");
                }
            }
        }
    }
}

.node-webform {
    background: $light-grey;
    padding-top: rem(80);
    padding-bottom: rem(110);

    font-size: rem(18);
    color: $charcoal;
    line-height: rem(26);

    h3 {
        color: $charcoal;
        margin-top: 0;
    }
}
