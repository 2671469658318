#header {
    background: $blue;
    color: $white;

    .container {
        padding-top: rem(20);
        padding-bottom: rem(20);
        position: relative;
        @media only screen and (max-width: $screen-xs-max) {
            padding-bottom: 0;
        }
    }

    a {
        color: $white;
    }

    .site-title {
        display: inline-block;
        font-size: rem(26);
        font-weight: bold;
        line-height: rem(30);
        margin-top: rem(11);
        @media only screen and (max-width: $screen-xs-max) {
            font-size: rem(16);
            margin: 0;
            padding-bottom: rem(20);
        }
    }

    img {
        display: inline-block;
    }

    .primary-navigation {
        a {
            margin: rem(15) 0;
            @media only screen and (max-width: $screen-xs-max) {
                margin: 0;
            }
        }
    }

    [data-trigger-name="search"] {
        @media only screen and (max-width: $screen-sm-max) {
            display: none;
        }
    }

    .search-form {
        border-bottom: rem(2) solid $white;
        margin: rem(11) 0;
        padding-top: rem(2);

        @media only screen and (max-width: $screen-xs-max) {
            background-color: $white;
            margin: 0 -15px;
            padding: rem(15) rem(54) rem(15) rem(24);
        }

        label {
            display: none;
        }

        input[type="text"] {
            background: transparent;
            border: 0;
            border-radius: 0;
            color: $white;
            outline: none; // not accessible
            padding: rem(5) rem(30) rem(5) 0;
            width: 100%;

            &::placeholder {
                color: $white;
            }

            @media only screen and (max-width: $screen-xs-max) {
                border-bottom: 3px solid $blue;
                color: $blue;
                padding-bottom: rem(2);
                &::placeholder {
                    color: $blue;
                }
            }
        }

        button[type="submit"].btn {
            background-color: transparent;
            background-image: url("../images/icon-search-white.svg");
            background-repeat: no-repeat;
            background-position: center right;
            border: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: rem(15);
            bottom: rem(15);
            right: 15px;
            width: rem(30);

            @media only screen and (max-width: $screen-xs-max) {
                background-image: url("../images/icon-search-blue.svg");
                right: rem(24);
            }
        }
    }

    .header-logo {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: calc(8.33333% - 30px);
    }

    .responsive-menu .header-logo {
        background-color: rgba($black, 0.2);
        margin: rem(1) -15px 0;
        padding: 20px 0;
        position: static;
        right: 0;
        text-align: center;
        top: auto;
        transform: none;
        width: auto;
    }
}

.article-title {
    background: rgba(9, 40, 105, 0.05);
    padding-top: rem(50);
    padding-bottom: rem(35);

    a {
        font-weight: bold;
        font-size: rem(16);
        color: $blue;
        line-height: rem(36);
    }

    .news-date {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: rem(14);
        color: #2b2b2b;
        line-height: rem(24);
    }
}

// Main event header
.event-header {
    padding: rem(60) 0;
    background-image: url("../images/rings.svg");
    background-repeat: no-repeat;
    background-position: 95% center;
    position: relative;

    @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        background-position: calc(100% + 130px) center;
    }

    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        background-position: calc(100% + 130px) center;
        background-size: 550px;
    }

    @media (max-width: $screen-xs-max) {
        background-position: calc(100% + 35px) -120px;
        background-size: 300px;
        padding-top: 150px;
    }
}

.node-type-event {
    .event-header {
        padding: 0;
        background: none;
    }
}

#block-block-2,
.event-header {
    .event-title {
        font-size: rem(60);
        line-height: rem(66);
        margin-bottom: rem(50);

        .node-type-event & {
            margin-top: rem(60);
        }
    }

    .event-image {
      margin-top: rem(1);
    }

    .event-date {
        position: relative;
        font-size: rem(20);
        line-height: rem(36);
        padding-left: rem(50);

        &:before {
            content: ' ';
            position: absolute;
            width: 36px;
            height: 36px;
            left: 0;
            top: 0;
            background-image: url("../images/icons/primary/event/calendar-header.png");
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .event-map-link {
        position: relative;
        padding-left: rem(50);

        &:before {
            content: ' ';
            position: absolute;
            width: 36px;
            height: 36px;
            left: 0;
            top: 0;
            background-image: url("../images/icons/primary/event/location-pin-header.png");
            background-repeat: no-repeat;
            background-position: center;
            // background-size: contain;
        }

        a {
            font-size: rem(20);
            color: #b80068;
            line-height: rem(30);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
h1.event-title.basic-title {
  margin-top: rem(59.2);
}
