.paragraph {
    margin: rem(60) 0;

    .embedded-video {
        .player {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;

            iframe,
            object,
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &--50-50-panel {
        .copy-wrapper {
            margin: 0 auto;
            width: 50%;

            @media (max-width: $screen-xs) {
                width: 90%;
            }
        }
    }

    &--image-text {
        margin: 0;
        padding: rem(40) 0;

        @media (max-width: $screen-xs) {
            padding-bottom: rem(10);
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }

        a {
            color: $pink;
        }

        .copy {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    &--session-video {
        .session-video {
            margin: 0 auto;
            float: none;
        }

        .speaker {
            h4 {
                font-size: rem(16);
                color: $charcoal;
                letter-spacing: 0;
            }

            p {
                font-size: rem(14);
                color: $charcoal;
                line-height: rem(24);
            }
        }
    }

    &--quote {
        position: relative;

        &-author {
            position: relative;

            @media (max-width: $screen-xs) {
                padding-top: rem(50);
            }


            .profile-image {
                margin-right: rem(15);
                width: rem(60);
                height: rem(60);
                position: relative;
                overflow: hidden;
                border-radius: 50%;

                @media (max-width: $screen-xs) {
                    width: rem(40);
                    height: rem(40);
                    margin-right: rem(5);
                }

                img {
                    display: inline-block;
                    margin: 0 auto;
                    height: 100%;
                    width: auto;
                }
            }

            p {
                span {
                    font-weight: bold;
                    color: $blue;
                }

                font-size: rem(14);
                color: $blue;
                line-height: rem(22);
            }

            &:before {
                content: " ";
                background-image: url("../images/icon-quote.svg");
                background-repeat: no-repeat;
                width: rem(56);
                height: rem(58);
                position: absolute;
                top: rem(-20);
                left: rem(-80);

                @media (max-width: $screen-xs) {
                    left: rem(-30);
                }
            }
        }

        &-text {
            margin-top: rem(15);
            font-weight: bold;
            font-size: rem(24);
            color: $pink;
            line-height: rem(40);

            @media (max-width: $screen-xs) {
                font-size: rem(20);
                line-height: rem(28);
            }
        }
    }

    &--video {
    }

    &--other-galleries {
        margin-bottom: 0;
        padding: rem(50) 0;

        .gallery-container {
            margin-top: rem(50);
        }
    }

    &--floorplan .btn-download {
        margin-bottom: 0;
    }

    &.pink {
        background: $light-pink;
    }

    &.grey {
        background: $light-grey;
    }

    &.grey,
    &.pink {
        margin: 0;
        padding: rem(40) 0;
    }
}
