@import url(https://fonts.googleapis.com/css?family=Vollkorn:400italic,400);
@import url(https://fonts.googleapis.com/css?family=Lato:300,300italic,400,400italic,700,700italic,900,900italic);

body, html {
	color: #2B2B2B;
	font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: rem(16);
	line-height: 1.5em;
	height: 100%;
}

body {
	display: flex;
	flex-direction: column;
}

.main-content {
	flex: 1 0 auto;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
	color: $blue;
}

h1,
.h1 {
	color: $blue;
	font-weight: bold; 
	font-size: rem(36);
	line-height: rem(48);
}

h2 {
	font-size: rem(28);
	line-height: rem(48);
}

h3 {
	font-size: rem(24);
	line-height: rem(36);
}

h4 {
	font-size: rem(20);
	line-height: rem(24);
}

h5 {
	font-size: rem(16);
	line-height: rem(24);
}

a,
a:visited {
	color: $blue;
}

a:hover {
	text-decoration: none;
}

strong {
	font-weight: 900;
}

.link {
	font-weight: bold;
	font-size: rem(16);
	color: $pink;
	line-height: rem(36);

	&--arrow-right {
		background-image: url("../images/icons/primary/event/right.png");
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: rem(35);

		.front & {
			color: $blue;
			background-image: url("../images/icons/primary/nhs/right.png");
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&--arrow-left {
		background-image: url("../images/icons/primary/event/left.png");
		background-repeat: no-repeat;
		background-position: left center;
		padding-left: rem(35);

		.front &,
		.node-type-article & {
			color: $blue;
			background-image: url("../images/icons/primary/nhs/left.png");
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&--external {
		background-image: url("../images/icons/primary/event/external-link.png");
		background-repeat: no-repeat;
		background-position: left center;
		padding-left: rem(35);

		.front & {
			color: $blue;
			background-image: url("../images/icons/primary/nhs/external-link.png");
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&:hover,
	&:focus {
		span {
			text-decoration: underline;
		}
	}
}

.btn {
	border: rem(2) solid;
	text-align: left;
	padding: rem(18) rem(58);
	margin: rem(20) 0;
	border-radius: rem(2);
	white-space: inherit;

	i { 
		display: none;
	}
	
	&-blue,
	&-primary {
		background-color: $blue;    
		border-color: $blue;
		color: $white !important;

		&:hover {
			background-color: $white;
			color: $blue !important;
		}
	}

	&-white {
		background-color: $white !important;
		color: $blue !important;
		border-color: $blue !important;

		&:hover {
			background-color: $blue !important;
			color: $white !important; 
			border-color: $blue !important;
		}
	}

	&-icon {
		padding: rem(18) rem(38);
		position: relative;


		span {
			padding-right: rem(48);
		}

		&:before {
			content: "";
			position: absolute;
			width: rem(24);
			height: rem(24);
			top: 50%;
			right: rem(24);
			transform: translateY(-50%);
			background-image: url("../images/icons/primary/nhs/right.png");
			background-repeat: no-repeat;
			background-position: right center;
		}

		&:hover:before {
			background-image: url("../images/icons/white/right.png");
		}

		&.btn-external {
			&:before {
				background-image: url("../images/icons/primary/nhs/external-link.png");
			}

			&:hover:before {
                background-image: url("../images/icons/white/external-link.png");
            }
		}

		&.btn-add {
			&:before {
				background-image: url("../images/icons/primary/nhs/plus.png");
			}

			&:hover:before {
                background-image: url("../images/icons/white/plus.png");
            }
		}

		&.btn-download {
			&:before {
				background-image: url("../images/icons/primary/event/download.png");
			}

			&:hover:before {
                background-image: url("../images/icons/white/download.png");
            }
		}
	}

	&-cta {
		margin-top: rem(50);
		margin-bottom: rem(-50);
	}
}

.caption {
	font-size: rem(14);
	color: $blue;
	line-height: rem(22.4);
	margin-top: rem(10);
}

.field-type-text-long {
    white-space: pre-line;
}