.view-search-results {
    .view-header {
        padding: rem(30) 0;
        background: $light-grey;
        font-size: rem(24);
        color: $blue;
        line-height: rem(36);
        margin-bottom: rem(40);
    }

    .breadcrumb {
        font-size: rem(14);
        color: $blue;
        line-height: rem(24);
        padding: 0;
        margin: 0;
        background: none;
        border-radius: 0;
        margin-bottom: rem(20);
    }

    h3 {
        margin-bottom: 0;
    }

    .link--arrow-right {
        background-image: url("../images/icons/primary/nhs/right.png");
    }
}
