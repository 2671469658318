.news-article {
    padding-top: rem(10);

    h3 {
        font-size: rem(24);
        color: $charcoal;
        line-height: rem(36);
    }

    .article-date {
        font-weight: bold;
        font-size: rem(14);
        color: $charcoal;
        line-height: rem(24);
        margin-bottom: rem(25);
    }

    a {
        color: $blue;

        &.link--arrow-right {
            background-image: url("../images/icons/primary/nhs/right.png");
        }
    }
}

.node-exhibitor {
    .stand-number {
        font-weight: bold;
        font-size: rem(18);
        color: $pink;
    }

    .exhibitor-pic {
        margin-top: rem(45);
    }
}

.node-page {
    .back-link {
        margin-top: rem(-50);
        display: block;
    }
}