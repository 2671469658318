.event-navigation-wrapper {
    background: $pink;
    color: $white;
    padding: rem(20) 0;
    @media only screen and (max-width: $screen-xs-max) {
        padding-bottom: 0;
        padding-top: 0;
    }

    .container {
        text-align: center;
    }

    a {
        color: $white;
    }
    .responsive-menu ul {
        li {
            &.active a,
            &.active-trail a {
                border-bottom-color: $white;
            }
        }
    }

    .section-title {
        line-height: rem(30);
        padding-bottom: rem(20);
    }
}

.responsive-menu {
    @media only screen and (max-width: $screen-xs-max) {
        display: none;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        @media only screen and (max-width: $screen-xs-max) {
            margin-left: -15px;
            margin-right: -15px;
        }
        li {
            display: inline-block;
            + li {
                margin-left: rem(50);
            }

            @media only screen and (max-width: $screen-sm-max) {
                + li {
                    margin-left: rem(30);
                }
            }

            @media only screen and (max-width: $screen-xs-max) {
                background-color: rgba($black, 0.2);
                display: block;
                text-align: left;
                padding: rem(15) rem(15) rem(15) rem(30);
                + li {
                    margin-left: 0;
                    margin-top: 1px;
                }
            }
        }
        a {
            border-bottom: rem(3) solid transparent;
            display: block;
            padding-top: rem(3);
            &:hover,
            &:focus,
            &.focus,
            &.active {
                border-bottom-color: $white;
                text-decoration: none;
            }
            @media only screen and (max-width: $screen-xs-max) {
                display: inline-block;
            }
        }
    }

}

button[data-trigger-target] {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    float: right;
    height: rem(40);
    margin: -5px 0 -5px 5px;
    padding: 0;
    width: rem(40);
    &.menu-trigger {
        background-image: url("../images/icon-menu-white.svg");
    }
    &.submenu-trigger {
        background-image: url("../images/icon-submenu-white.svg");
    }
    &.search-trigger {
        background-image: url("../images/icon-search-white.svg");
        @media only screen and (min-width: $screen-sm-min) {
            margin-top: rem(10);
        }
    }
    &.active {
        background-image: url("../images/icon-close-white.svg");
    }
}
