// Colors
$pink: #B80068;
$blue: #092869;
$cyan: #00A9E0;
$white: #FFFFFF;
$charcoal: #2B2B2B;
$black: #0F0F10;
$dark-grey: #262525;

$light-grey: #F2F4F7;
$light-pink: #F7E5EF;
$light-blue: #DBDEE9;

$nhs-dark-blue: #002D72;
$nhs-light-blue: #092869;

$burnt-orange: #D43900;
$gold: #8D6708;
$purple: #AA00AA;
$green: #008000;
$red: #B22222;

$light-purple: rgba(170, 0, 170, 0.05);

// Typography
$base-font-size: 16;
