.upcoming-events {
    .upcoming-events-container {
        display: flex;
        flex-wrap: wrap;
    }

    .upcoming-event {
        display: flex;
        margin-bottom: rem(30);

        a {
            display: inline-block;
            background: $light-grey;
            font-weight: bold;
            font-size: rem(20);
            color: $dark-grey;
            line-height: rem(24);

            &:hover {
                .upcoming-event-info {
                    span {
                        text-decoration: underline;
                    }

                    .event-date,
                    .event-date span {
                        text-decoration: none;
                    }
                }
            }

            .upcoming-event-info {
                position: relative;

                span {
                    display: inline-block;
                    padding: rem(20);
                    text-decoration: none;
                }

                .event-date {
                    padding: 0 rem(10);

                    span {
                        padding: 0;
                    }

                    position: absolute;
                    font-weight: bold;
                    font-size: rem(14);
                    color: $blue;
                    line-height: rem(24);
                    top: rem(-10);
                    left: rem(10);
                    background: $light-grey;
                }
            }
        }
    }
}

.event-introduction {
    font-weight: bold;
    font-size: rem(24);
    color: $charcoal;
    line-height: rem(36);
    padding: rem(60) 0;

    .purple & {
        background: $light-purple;
    }
}
