.region-footer {
    flex-shrink: 0;
}
.footer {
    background: $charcoal;
    border-top: rem(3) solid #00A9E0;

    .container {
        padding-top: rem(65);
        padding-bottom: rem(65);
        position: relative;
    }

    .footer-logo {
        text-align: right;

        img {
            display: inline-block;
        }

        @media only screen and (max-width: $screen-xs-max) {
            margin-top: rem(40);
            text-align: center;
        }
    }

    .footer-links {
        margin: 0;
        padding: 0;
        column-count: 2;

        li {
            margin-bottom: rem(10);

            a {
                color: #fff;

                &:hover,
                &:focus,
                .focus {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
}
