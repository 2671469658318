.faq-selector {
    margin: rem(50) 0;

    .box-container {
        display: flex;
        flex-wrap: wrap;

        .box {
            display: flex;
            margin: 0 -1px rem(30);

            a {
                background: $light-grey;
                display: inline-block;
                width: 100%;
                text-align: center;
                padding: rem(15);
                border: rem(3) solid $light-grey;
                font-weight: bold;
                font-size: rem(20);
                color: $blue;
                line-height: rem(24);

                @media (max-width: $screen-xs) {
                    font-size: rem(16);
                    line-height: rem(16);
                }

                img {
                    margin: 0 auto;
                    margin-bottom: rem(20);
                }

                &:hover,
                &:focus {
                    border-color: $pink;
                }
            }
        }
    }
}
