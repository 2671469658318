.home-header {
    margin-top: rem(60);
    padding-bottom: 2.5rem;
    position: relative;

    @media (max-width: 900px) {
        padding-bottom: 0;
    }

    @media (max-width: $screen-xs-max) {
        margin-top: rem(15);
    }

    h1 {
        font-weight: bold;
        font-size: rem(60);
        color: $blue;
        line-height: rem(66);
        margin-top: rem(50);
        margin-bottom: rem(30);

        @media (max-width: $screen-xs-max) {
            font-size: rem(36);
            text-align: center;
            line-height: rem(48);
            margin-top: rem(20);
            margin-bottom: rem(5);
        }
    }

    .down {
        display: inline-block;
        text-indent: -9999px;
        width: 36px;
        height: 36px;
        background-image: url("../images/icons/primary/nhs/down.png");
        background-repeat: no-repeat;
        background-position: center;
        margin-top: rem(45);
    }

    .introduction {
        font-size: rem(20);
        line-height: rem(30);

        @media (max-width: $screen-xs-max) {
            font-size: rem(18);
            text-align: center;
            line-height: rem(26);
        }
    }

    img {
        @media (max-width: $screen-xs-max) {
            max-width: rem(235);
            margin: 0 auto;
        }
    }
}

.page-header {
    margin-top: rem(60);
    padding-bottom: 0;
    position: relative;
    border: 0;

    h1 {
        font-weight: bold;
        font-size: rem(60);
        color: $blue;
        line-height: rem(66);
        margin-top: 0;
        margin-bottom: rem(30);

        @media (max-width: $screen-xs-max) {
            font-size: rem(36);
            text-align: center;
            line-height: rem(48);
            margin-top: rem(20);
            margin-bottom: rem(5);
        }
    }

    .introduction {
        font-size: rem(20);
        line-height: rem(30);

        @media (max-width: $screen-xs-max) {
            font-size: rem(18);
            text-align: center;
            line-height: rem(26);
        }
    }

    img {
        @media (max-width: $screen-xs-max) {
            max-width: rem(235);
            margin: 0 auto;
        }
    }
}

.nhs-block {
    margin: rem(40) 0;

    @media (max-width: $screen-xs-max) {
        margin: rem(40) 0;
    }

    &.grey {
        background-color: $light-grey;
    }

    &.grey-curved {
        background-image: url("../images/mustache-footer.svg");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
    }

    &--home-content {
        .image-left {
            .copy {
                padding-left: rem(35);
            }
        }

        .copy {
            margin-top: rem(40);

            .link {
                color: $blue;

                &--arrow-right {
                    background-image: url("../images/icons/primary/nhs/right.png");
                }
            }
        }
    }

    &--home-register {
        padding: rem(50) 0;

        @media (max-width: $screen-xs-max) {
            padding: 0 0 rem(40);
        }

        p {
            font-size: rem(20);
            line-height: rem(30);
        }

        a {
            text-decoration: underline;
        }
    }

    &--home-upcoming-events {
        .h1 {
            margin-top: 0;
        }

        .upcoming-event {
            margin-top: rem(32);
        }

        .image-left {
            .copy {
                padding-left: rem(35);
            }
        }

        .copy {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .link {
                color: $blue;

                &--arrow-right {
                    background-image: url("../images/icons/primary/nhs/right.png");
                }
            }

            h3 {
                a {
                    color: $dark-grey;
                }
            }
        }

        .btn {
            margin-top: rem(50);
        }
    }

    &--home-past-events {
        background-image: url("../images/mustache-half.svg");
        background-repeat: no-repeat;
        background-position: center 43%;
        margin: 0;
        padding: rem(160) 0;

        h2 {
            font-size: rem(36);
            margin-top: 0;
        }

        .link {
            color: $blue;

            &--arrow-right {
                background-image: url("../images/icons/primary/nhs/right.png");
            }
        }
    }

    &--home-twitter-news {
        margin-top: 0;
        position: relative;
        padding-bottom: rem(10);
        
        h2 {
            margin-bottom: rem(20);
        }

        .tweet {
            padding-top: rem(30);

            .tweet-header {
                margin-bottom: rem(20);

                img,
                .twitter-handle {
                    display: inline-block;
                    vertical-align: middle;
                }

                .twitter-handle {
                    margin-left: rem(20);

                    h3 {
                        font-weight: bold;
                        font-size: rem(20);
                        color: $dark-grey;
                        line-height: rem(24);
                        margin: 0;
                    }
                    p {
                        margin: 0;
                    }
                }
            }

            .tweet-body {
                img {
                    margin-bottom: rem(20);
                }
            }
        }

        .link {
            color: $blue;

            &--arrow-right {
                background-image: url("../images/icons/primary/nhs/right.png");
            }
        }
    }

    &--footer-register {
        margin-bottom: 0;
        padding-top: rem(80);

        p {
            font-size: rem(20);
            color: $charcoal;
            line-height: rem(30);
        }

        .form {
            &.register-interest {
                margin-top: 0;
            }
        }
    }

    &--footer-past-events {
        margin-bottom: 0;
        padding-top: rem(80);
        padding-bottom: rem(60);

        h2 {
            font-size: rem(36);
        }

        a {
            text-decoration: underline;
        }

        .link {
            color: $blue;

            &--arrow-right {
                background-image: url("../images/icons/primary/nhs/right.png");
            }
        }
    }
}

.block-views {
    margin: rem(40) 0;
}

