#mc_embed_signup {
    background: none;
    clear: left;
    margin-top: rem(32);

    form {
        padding: 0;
    }

    input {
        border: rem(2) solid $blue;
        border-radius: 0;
        &[type="submit"] {
            border-radius: rem(2);
            width: auto !important;
        }
    }

    .mc-field-group {
        select {
            background-color: $white;
            width: 100%;
        }
        .asterisk {
            color: transparent;
            font-size: inherit;
            top: 0;
            width: 0;
            &:before {
                color: $blue;
                content: ' (Required)';
            }
        }
    }

    .terms-and-conditions {
        left: -0.3em;
    }

    #mc-embedded-subscribe {
        margin: 0;
        margin-top: rem(15);
        text-align: center;
        width: 96%;
    }
}

#mc-embedded-subscribe-form {
    input[type="checkbox"] {
        display: inline;
        width: auto;
        margin-right: 10px;
    }
    .mc_fieldset {
        border: none;
        min-height: 0px;
        padding-bottom: 0px;
    }
}

#mergeRow-gdpr {
    margin-top: 20px;
    fieldset {
        label {
            font-weight: normal;
        }
    }
}
