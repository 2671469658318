.nhs-pager {
    list-style: none;
    margin: rem(20) 0;
    padding: 0;

    li {
        display: inline-block;
        padding: rem(5) rem(9);
        margin: 0 rem(5);
        vertical-align: middle;
        font-size: rem(18);
        line-height: rem(18);
        border: 2px solid $white;
        border-radius: rem(16);

        &.active {
            border: 2px solid $cyan;
        }

        a {
            font-size: rem(18);
            color: $blue;
            border-bottom: 2px solid $white;

            &:hover {
                text-decoration: none;
                border-bottom: 2px solid $cyan;
            }
        }

        &.prev,
        &.next {
            padding: 0;
            border: 0;
            background-image: url("../images/icons/primary/nhs/right.png");
            background-repeat: no-repeat;
            background-position: left center;
            width: 24px;
            height: 24px;

            a, span {
                width: 24px;
                height: 24px;
                text-indent: -9999px;
                display: inline-block;
            }
        }

        &.prev {
            transform: scaleX(-1);
        }

        &.next {
        }

        &.disabled {
            opacity: 0.5;
        }
    }
}
