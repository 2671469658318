.page-title {
    background: $light-grey;

    padding: rem(80) 0 rem(60);

    .node-exhibitor & {
        padding: rem(40) 0;
    }

    h1,
    p {
        margin-top: 0;
    }
}