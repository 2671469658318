.photo-gallery {
    .gallery {
        margin-bottom: rem(30);

        a {
            display: block;
            background: $light-grey;
            font-weight: bold;
            font-size: rem(20);
            color: $black;
            line-height: rem(24);

            @media only screen and (max-width: $screen-xs-max) {
                display: inline-block;
            }

            span {
                display: inline-block;
                padding: rem(20);
            }
        }

        &:hover {
            a {
                span {
                    text-decoration: underline;
                }
            }
        }
    }
}
