.winners-table,
.others-table {
    tbody {
        tr {
            td {
                background: $light-blue;
                box-shadow: 0;
                border: 1px solid $white;
                padding: rem(15) rem(20);

                font-size: rem(14);
                color: $charcoal;
                letter-spacing: 0;
                line-height: rem(20);

                a {
                    text-decoration: underline;
                }

                &.views-field-field-poster-number {
                    width: 10%;
                }

                &.views-field-title {
                    width: 45%;
                }

                &.views-field-field-lead-author-name {
                    width: 20%;
                }

                &.views-field-field-lead-author-organisation {
                    width: 25%;
                }
            }

            &.grouping-row {
                td {
                    border: 0;
                    background: rgba(43, 43, 43, 0.05);
                }
            }
        }
    }
}

.winners-table {
    thead {
        tr {
            th {
                font-weight: normal;
                background: $blue;
                color: $white;
                vertical-align: middle !important;
                border: 0;
                box-shadow: 0;
                padding: rem(15) rem(20);
            }
        }
    }
}

.others-table {
    thead {
        tr {
            th {
                font-weight: bold;
                background: rgba(43, 43, 43, 0.05);
                vertical-align: middle !important;
                border: 0;
                box-shadow: 0;
                padding: rem(15) rem(20);
            }
        }
    }
}

.poster-group {
    h4 {
        color: $white;
        font-weight: normal;
        font-size: rem(16);
        line-height: rem(17);
        margin: 0;
        margin-bottom: 1px;

        a {
            background: $blue;
            color: $white;
            padding: rem(15) rem(20);
            display: block;
            position: relative;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:before {
                content: "";
                position: absolute;
                width: 32px;
                height: 20px;
                right: rem(20);

                background-image: url("../images/icons/chevron-down.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                transform: scaleY(-1);
                @media only screen and (max-width: $screen-xs-max) {
                    width: 18px;
                }
            }

            &.collapsed {
                &:before {
                    transform: none;
                }
            }
        }
    }
}

.winners-table,
.others-table {
    @media only screen and (max-width: 485px) {
        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tbody {
            tr {
                border-bottom: 1px solid $white;

                td {
                    /* Behave  like a "row" */
                    border: none !important;
                    position: relative;
                    width: 100% !important;
                    padding: rem(30) rem(10) rem(10);

                    &:before {
                        /* Now like a table header */
                        position: absolute;
                        /* Top/left values mimic padding */
                        top: 0;
                        left: 0;
                        width: 45%;
                        white-space: nowrap;
                        padding: rem(10);
                        color: $blue;
                    }

                    /* Label the data */
                    &:nth-of-type(1):before {
                        content: "Poster Number";
                    }
                    &:nth-of-type(2):before {
                        content: "Title";
                    }
                    &:nth-of-type(3):before {
                        content: "Main Author";
                    }
                    &:nth-of-type(4):before {
                        content: "Organisation";
                    }
                }

                /* Hide pseudo content for grouping rows */
                &.grouping-row {
                    border: none;

                    td {
                        padding-top: rem(10);

                        &:before {
                            content: "";
                        }
                    }
                }
            }
        }
    }
}
