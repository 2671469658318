.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.center-row-11 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.center-row-10 {
    margin: 0 8.333%;
}

.center-row-7 {
    margin: 0 20.833%;

    @media (max-width: $screen-xs) {
        margin: 0 10.4165%;
    }

}