.table {
    border-collapse: collapse;
}

.programme-controls .btn {
    padding-left: 48px;
    padding-right: 48px;
    &.btn-white {
        border-color: transparent !important;
    }
}

.tablefield_extended,
.pre-event-table {
    thead {
        tr {
            th {
                text-align: center;
                color: $white;
                background: $blue;
                border: none;
                font-weight: normal;
                font-size: rem(16);
                letter-spacing: 0;
                padding: rem(20);

                &.row_0.col_0 {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 50%;
                        height: 45%;
                        width: 100%;
                        transform: translateY(-50%);
                        border-right: 2px solid #fff;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-of-type(odd) {
                background: $light-blue;
            }

            td {
                border: none;
                padding: rem(20);

                &.col_1 {
                    text-align: center;

                    @media only screen and (max-width: 485px) {
                        text-align: left;
                    }
                }
            }

            &.grouping-row {
                background: $white;

                td {
                    border: 0;
                    background: rgba(43, 43, 43, 0.05);
                    font-weight: bold;
                }
            }
        }
    }

    @media only screen and (max-width: 485px) {
        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tbody {
            tr {
                border-bottom: 1px solid $white;

                td {
                    /* Behave  like a "row" */
                    border: none !important;
                    position: relative;
                    width: 100% !important;
                    padding: rem(30) rem(10) rem(10);
                    text-align: left;

                    &:before {
                        /* Now like a table header */
                        position: absolute;
                        /* Top/left values mimic padding */
                        top: 0;
                        left: 0;
                        width: 45%;
                        white-space: nowrap;
                        padding: rem(10);
                        color: $blue;
                    }
                }

                /* Hide pseudo content for grouping rows */
                &.grouping-row {
                    border: none;

                    td {
                        padding-top: rem(10);

                        &:before {
                            content: "";
                        }
                    }
                }
            }
        }
    }
}

.pre-event-table {
    th {
        padding: rem(15) rem(20) !important;
        font-size: rem(14);
        letter-spacing: 0;
        line-height: rem(20);
        text-align: left;
        font-weight: bold;
    }

    tr {
        td {
            padding: rem(15) rem(20) !important;
            font-size: rem(14);
            color: $charcoal;
            letter-spacing: 0;
            line-height: rem(20);

            a {
                text-decoration: underline;
            }
        }
    }
}

.by-place-of-work,
.by-role {
    @media only screen and (max-width: 485px) {
        h3 {
            color: $white;
            background: $blue;
            font-size: rem(17);
            line-height: rem(22);
            margin-bottom: 0;
            padding: rem(10);

        }

        tbody {
            tr {
                td {
                    /* Label the data */
                    &:before {
                        content: attr(data-before);
                    }
                }
            }
        }
    }
}

// Bootstrap override
.table-responsive {
    & > .table {
        & > thead,
        & > tbody {
            & > tr {
                & > th,
                & > td {
                    white-space: normal;
                }
            }
        }
    }
}