.royalSlider {
    width: 100%;
    height: 550px;
    margin: rem(40) 0;

    &.rsDefault {
        .rsOverflow,
        .rsThumbs {
            background: #fff !important;
        }
        .rsThumbs {
            padding-top: 2px !important;
        }
        &.rsHor {
            .rsArrowIcn {
                display: block;
                direction: ltr;
                height: 43px;
                text-indent: -9999em;
                width: 43px;
            }
            .rsArrowLeft {
                .rsArrowIcn {
                    background: transparent url(../images/ui-page-prev.png) no-repeat;
                    margin-left: -15px;
                }
            }
            .rsArrowRight {
                .rsArrowIcn {
                    background: transparent url(../images/ui-page-next.png) no-repeat;
                    margin-left: -30px;
                }
            }
        }
    }
}

.field-name-field-image-galleries {
    width: 100%;
}

.node-video-gallery {
    .photo-gallery {
        padding: rem(140) 0;
    }
}

.node-video {
    .embedded-video {
        margin: rem(40) 0;
    }
}